import { IceButtonTab } from '@icepanel/component-lib'
import { Module, VuexModule } from 'vuex-module-decorators'

export interface IOverviewModule {}

const name = 'overview'

@Module({
  name,
  namespaced: true
})
export class OverviewModule extends VuexModule {
  static namespace = name

  get timelineTabs (): IceButtonTab[] {
    return [
      { id: 'today', text: 'Today' },
      { id: 'yesterday', text: 'Yesterday' },
      { id: 'week', text: 'This week' },
      { id: 'month', text: 'This month' },
      { id: 'all', text: 'All time' }
    ]
  }
}
